window.esign = window.esign || {};

esign.init = function () {
  $("html").removeClass("no-js").addClass("js");

  Response.create({
    prop: "width",
    prefix: "min-width- r src",
    breakpoints: [767, 0],
    lazy: true,
  });

  esign.gaDevelopment();
  esign.cacheSelectors();
  esign.navigation();
  esign.responsiveVideos();

  esign.formValidation();
  esign.formAjax();
  esign.formAjaxRecaptcha();

  esign.language();
  esign.matchHeight();
  esign.inputToggle();
  esign.fancybox();
  esign.apiFetch();
  esign.anchorScroll();
  esign.locations();
};

esign.locations = function () {
  var stores = [];
  // This is the result set of markers in area
  var map;
  var info_div = document.getElementById("locations");
  var contactRoute = window.contact_route;
  var contactButton = window.contact_button;
  var openedInfoWindow = null;

  function initMap() {
    info_div.innerHTML = `<div class="location__wrapper"><div class="location-info"><p class="location-name">
                            Art Cube Gent </p><p class="location-address"> Hurstweg 8, 9000 Gent
                          </p></div><a href="${contactRoute}" class="button button--light location-button">${contactButton}</a></div>`;
    const styledMapType = new google.maps.StyledMapType(
      [
        {
          featureType: "administrative.locality",
          elementType: "all",
          stylers: [
            {
              hue: "#2c2e33",
            },
            {
              saturation: 7,
            },
            {
              lightness: 19,
            },
            {
              visibility: "on",
            },
          ],
        },
        {
          featureType: "landscape",
          elementType: "all",
          stylers: [
            {
              hue: "#ffffff",
            },
            {
              saturation: -100,
            },
            {
              lightness: 100,
            },
            {
              visibility: "simplified",
            },
          ],
        },
        {
          featureType: "poi",
          elementType: "all",
          stylers: [
            {
              hue: "#ffffff",
            },
            {
              saturation: -100,
            },
            {
              lightness: 100,
            },
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "geometry",
          stylers: [
            {
              hue: "#bbc0c4",
            },
            {
              saturation: -93,
            },
            {
              lightness: 31,
            },
            {
              visibility: "simplified",
            },
          ],
        },
        {
          featureType: "road",
          elementType: "labels",
          stylers: [
            {
              hue: "#bbc0c4",
            },
            {
              saturation: -93,
            },
            {
              lightness: 31,
            },
            {
              visibility: "on",
            },
          ],
        },
        {
          featureType: "road.arterial",
          elementType: "labels",
          stylers: [
            {
              hue: "#bbc0c4",
            },
            {
              saturation: -93,
            },
            {
              lightness: -2,
            },
            {
              visibility: "simplified",
            },
          ],
        },
        {
          featureType: "road.local",
          elementType: "geometry",
          stylers: [
            {
              hue: "#e9ebed",
            },
            {
              saturation: -90,
            },
            {
              lightness: -8,
            },
            {
              visibility: "simplified",
            },
          ],
        },
        {
          featureType: "transit",
          elementType: "all",
          stylers: [
            {
              hue: "#e9ebed",
            },
            {
              saturation: 10,
            },
            {
              lightness: 69,
            },
            {
              visibility: "on",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "all",
          stylers: [
            {
              hue: "#e9ebed",
            },
            {
              saturation: -78,
            },
            {
              lightness: 67,
            },
            {
              visibility: "simplified",
            },
          ],
        },
      ],
      {
        name: "Styled Map",
      }
    );

    // pick center coordinates for your map
    var myMapCenter = {
      lat: 50.874111415115706,
      lng: 4.2728952208070465,
    };

    // create map and say which HTML element it should appear in
    map = new google.maps.Map(document.getElementById("map"), {
      center: myMapCenter,
      zoom: 7,
      panControl: true,
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: true,
      streetViewControl: false,
      overviewMapControl: false,
      rotateControl: false,
    });

    //Associate the styled map with the MapTypeId and set it to display.
    map.mapTypes.set("styled_map", styledMapType);
    map.setMapTypeId("styled_map");

    stores = [
      {
        name: "Art Cube Gent",
        location: {
          lat: 51.07560083514123,
          lng: 3.7224210370743176,
        },
        address: "Hurstweg 8, 9000 Gent",
        website: "https://artcube.be/"
      },
      {
        name: "Paleis voor Schone Kunsten, Brussel",
        location: {
          lat: 50.84480442189876,
          lng: 4.35988244349306,
        },
        address: "Ravensteinstraat 23, 1000 Brussel",
        website: "https://www.bozar.be/nl",
      },
      {
        name: "Brussel",
        location: {
          lat: 50.05623575021421,
          lng: 5.256851896542715,
        },
        address: "Rue du Château 29, 6870 Saint-Hubert",
        website:
          "https://www.houseofweddings.com/nl/leveranciers/chateau-de-mirwart",
      },
      {
        name: "Cwart",
        location: {
          lat: 51.332536584781685,
          lng: 3.3111558978351807,
        },
        address: "Kalfstraat 42, 8300 Knokke-Heist",
        website: "https://www.cwart.be/nl",
      },
      {
        name: "Hoeve Engelendael",
        location: {
          lat: 51.271430080407754,
          lng: 3.570194597719113,
        },
        address: "Sint-Janspolderdijk 14, 9982 Sint-Laureins",
        website: "https://www.engelendael.be/",
      },
      {
        name: "Eskimofabriek",
        location: {
          lat: 51.071954891554995,
          lng: 3.7255697770089067,
        },
        address: "Wiedauwkaai 23, 9000 Gent",
        website: "https://www.eskimofabriek.be/",
      },
      {
        name: "Fabriekspand",
        location: {
          lat: 50.944123336118096,
          lng: 3.134623519844092,
        },
        address: "Veldstraat 59, 8800 Roeselare",
        website: "https://www.fabriekspand.be/",
      },
      {
        name: "De Filatuur",
        location: {
          lat: 50.94767622785029,
          lng: 4.036013120945524,
        },
        address: "Tragel 6, 9300 Aalst",
        website: "https://defilatuur.be/nl/home-2/",
      },
      {
        name: "Vaucelleshof",
        location: {
          lat: 51.26862678269591,
          lng: 3.3331488215475873,
        },
        address: "Vredestraat 17, 8340 Damme",
        website: "http://www.vaucelleshof.be/",
      },
      {
        name: "La Brugeoise",
        location: {
          lat: 51.192381072380996,
          lng: 3.2307690203100887,
        },
        address: "Vaartdijkstraat 5, 8200 Brugge",
        website: "http://www.labrugeoise.com/",
      },
      {
        name: "Kasteel Rozelaar",
        location: {
          lat: 51.08758005744097,
          lng: 3.833171566687898,
        },
        address: "Stationsstraat 105, 9080 Lochristi",
        website: "http://www.kasteelrozelaar.be/NL/Home",
      },
      {
        name: "'t Klein Reygaertsvliet",
        location: {
          lat: 51.3236563605622,
          lng: 3.3570822682271597,
        },
        address: "Nederenheerweg 14, 8300 Knokke-Heist",
        website:
          "https://m.facebook.com/profile.php?id=473678043127721&__tn__=C-R",
      },
      {
        name: "Hoeve 'T Goed Quaethem",
        location: {
          lat: 51.02172300094977,
          lng: 3.6006413972486624,
        },
        address: "Leernsesteenweg 401, 9800 Deinze",
        website: "http://tgoedquaethem.be/",
      },
      {
        name: "Feestzaal Oude Vismijn",
        location: {
          lat: 51.057818758919915,
          lng: 3.7202264904665636,
        },
        address: "Rekelingestraat 5, 9000 Gent",
        website: "https://oudevismijngent.wordpress.com/menukaart/",
      },
      {
        name: "Wijndomein Nobel",
        location: {
          lat: 51.10156463194042,
          lng: 3.843508731403686,
        },
        address: "Hoekskensstraat, 9080 Lochristi",
        website: "https://wijndomeinnobel.be/",
      },
      {
        name: "Château Charles",
        location: {
          lat: 50.5641733116251,
          lng: 5.795259089542879,
        },
        address: "Chalsèche 73, 4860 Pepinster",
        website: "http://www.chateaucharles.be/home_nl.php",
      },
      {
        name: "Kasteel van Ooidonk",
        location: {
          lat: 51.0029539596765,
          lng: 3.584317966528699,
        },
        address: "Ooidonkdreef 9, 9800 Deinze",
        website: "http://www.ooidonk.be/",
      },
      {
        name: "Garnier Antiques",
        location: {
          lat: 51.268304571503116,
          lng: 3.333663805657878,
        },
        address: "Vredestraat 17, 8340 Damme",
        website: "https://www.garnier.be/",
      },
    ];

    stores.forEach(function (store) {
      markStore(store);
    });
  }

  function deleteStoreInfo() {
    var info_div = document.getElementById("locations");
    info_div.innerHTML = `<div></div>`;
  }

  function showStoreInfo(storeInfo, loopIndex) {
    var info_div = document.getElementById("locations");
    deleteStoreInfo();
    info_div.innerHTML += `<div class="location__wrapper" data-aos="fade-up" data-aos-delay="${loopIndex}"><div class="location-info"><p class="location-name">
                            ${storeInfo.name} </p><p class="location-address"> ${storeInfo.address}
                          </p></div><a href="${contactRoute}" class="button button--light location-button">${contactButton}</a></div>`;
  }
  /* If you want to display tooltip on marker
                const contentString = `<div>Hello thar</div>`

                const infowindow = new google.maps.InfoWindow({
                  content: contentString,
                });
                */

  function markStore(storeInfo) {
    // Create a marker and set its position.

    const contentString = `
    <p class="location-tooltip"><strong>${storeInfo.name}</strong></p>
    <p class="location-tooltip">${storeInfo.address}</p>
    <a href="${storeInfo.website}" target="_blank"><u>Visit the website</u></a>
    `;
    const infowindow = new google.maps.InfoWindow({
      content: contentString,
    });

    storeInfo.marker = new google.maps.Marker({
      map: map,
      position: storeInfo.location,
      animation: google.maps.Animation.DROP,
      title: storeInfo.name,
    });

    storeInfo.marker.addListener("click", function () {
      if (openedInfoWindow != null) openedInfoWindow.close();
      showStoreInfo(storeInfo);
      infowindow.open(map, storeInfo.marker);
      openedInfoWindow = infowindow;
    });
  }
  if (info_div) {
    initMap();
  }
};
esign.anchorScroll = function () {
  function ea_scroll(hash) {
    var target = $(hash);

    if (target.length) {
      var top_offset = 0;
      if ($(".page-header").css("position") === "fixed") {
        top_offset = 100;
      }
      $("html,body").animate(
        {
          scrollTop: target.offset().top - top_offset,
        },
        1000
      );
      return false;
    }
  }
  if (window.location.hash) {
    ea_scroll(window.location.hash);
  }
};

esign.apiFetch = function () {
  var truncate = function (text) {
    var string = text.substring(0, 120);
    if (text.length > 120) {
      string += "&hellip;";
    }

    return string;
  };

  $(".js-api-fetch").each(function () {
    var $this = $(this);
    var api = $this.data("api");
    var limit = $this.data("limit"); // optional limitation
    var itemClass = $this.data("item-class");

    $.ajax({
      url: api,
      data: {},
      dataType: "jsonp",
      success: function (data) {
        if (data) {
          // get length of files
          var length = limit ? limit : data.length;
          var items = "";

          for (var i = 0; i < length; i++) {
            var el = data[i];
            items +=
              '<div class="' +
              itemClass +
              '">' +
              '<a href="' +
              el.url +
              '" target="_blank" class="image-card link">' +
              '<div class="image-card__image photo" style="background-image: url(' +
              el.photo +
              ')">' +
              '<img src="' +
              el.photo +
              '" alt="' +
              el.text +
              '" />' +
              "</div>" +
              '<span class="social-item-link__content"><span class="social-item-link__text">' +
              truncate(el.text) +
              "</span></span>" +
              "</a>" +
              "</div>";
          }

          $this.html(items);
        }
      },
    });
  });
};

esign.fancybox = function () {
  $("[data-fancybox]").fancybox({
    buttons: ["close"],
    /*btnTpl: {
      smallBtn:
        '<button type="button" data-fancybox-close class="fancybox-button fancybox-close-small" title="{{CLOSE}}">' +
        '<i class="icon-close" aria-hidden="true"></i>' +
        "</button>"
    },*/
    // touch: false,
    beforeLoad: function () {
      $("html").addClass("fancybox-active");
    },
    afterClose: function () {
      $("html").removeClass("fancybox-active");
    },
  });
};

esign.choices = function (Choices) {
  var $select = $(".js-choices");

  if ($select.length) {
    var choices = new Choices(".js-choices", {
      searchEnabled: false,
      shouldSort: false,
      itemSelectText: $select.attr("data-item-select-text"),
      placeholder: true,
    });
  }
};

esign.isotope = function (Isotope) {
  var $isotopeGrid = $(".js-isotope");
  var $filter = $(".filter");
  var iso;
  var qsRegex;
  var buttonFilter;

  var filterValue = function () {
    var $this = $(this);
    var searchResult = qsRegex ? $this.find(".h3").text().match(qsRegex) : true;
    var buttonResult = buttonFilter ? $this.is(buttonFilter) : true;
    return searchResult && buttonResult;
  };

  /*
  Isotope.prototype._positionItem = function( item, x, y ) {
    item.goTo( x, y );
  };*/

  if ($isotopeGrid.length && $filter.length) {
    if (typeof iso === "undefined") {
      iso = new Isotope(".js-isotope", {
        itemSelector: ".grid__item",
        masonry: {
          columnWidth: ".grid__item",
        },
        percentPosition: true,
        transitionDuration: 800,
        stagger: 0,
        hiddenStyle: {
          opacity: 0,
          transform: "scale(0.5)",
        },
        visibleStyle: {
          opacity: 1,
          transform: "scale(1)",
        },
      });
      $isotopeGrid.data("isotope", iso);

      $filter.on("click", ".filter__trigger", function () {
        buttonFilter = $(this).attr("data-filter");
        iso.arrange({ filter: buttonFilter });
        $filter.find(".active").removeClass("active");
        $(this).addClass("active");
      });

      $(window).resize(
        debounce(function () {
          window.setTimeout(function () {
            iso.arrange({ filter: buttonFilter });
          }, 800);
        })
      );
    } else {
      iso.arrange({ filter: "*" });
    }
  }

  var $searchTrigger = $(".js-search-toggle");
  var $searchContainer = $(".filter__search-bar");
  var $searchInput = $searchContainer.find("input");
  var $noResults = $(".no-results");

  $searchTrigger.on("click", function () {
    $searchContainer.toggleClass("open");
    $searchContainer.animate({
      width: "toggle",
    });

    if ($searchContainer.hasClass("open")) {
      $searchInput.focus();
    } else {
      $searchInput.val("");
      qsRegex = new RegExp($searchInput.val(), "gi");
      iso.arrange({ filter: filterValue });
    }
  });

  $searchInput.keyup(
    debounce(function () {
      qsRegex = new RegExp($searchInput.val(), "gi");

      iso.arrange({ filter: filterValue });

      if (iso.filteredItems.length === 0) {
        $noResults.fadeIn(800);
      } else {
        $noResults.hide(100);
      }
    })
  );

  var filterTerm = function (word) {
    return qsRegex ? word.match(qsRegex) : true;
  };

  function debounce(fn, threshold) {
    var timeout;
    threshold = threshold || 100;
    return function debounced() {
      clearTimeout(timeout);
      var args = arguments;
      var _this = this;
      function delayed() {
        fn.apply(_this, args);
      }
      timeout = setTimeout(delayed, threshold);
    };
  }
};

esign.inputToggle = function () {
  var $toggleContainer = $(".input-toggle");

  if ($toggleContainer.length) {
    var $toggle = $toggleContainer.find(
      ".input-toggle__trigger input[type=radio]"
    );
    var $toggleField = $toggleContainer.find(".input-toggle__field");

    if (isChecked($toggle)) {
      $toggleField.fadeIn(300);
    }

    $toggle.change(function (e) {
      var $input = $toggleField.find("input");

      if (isChecked($(this))) {
        $toggleField.fadeIn(300);
        $input.focus();
      } else {
        $toggleField.fadeOut(300);
        // $input.val('');
      }
    });

    function isChecked($checkbox) {
      if ($checkbox.attr("id") === "location-yes" && $checkbox.is(":checked")) {
        return $checkbox.is(":checked");
      } else {
        return false;
      }
    }
  }
};

esign.matchHeight = function () {
  $(".mh > *").matchHeight();
};

esign.language = function () {
  var $languageMenu = $(".language");
  var $languageTrigger = $languageMenu.find(".language__trigger");
  var $languageDropdown = $languageMenu.find(".language__wrap");

  $languageTrigger.on("click", function (e) {
    e.preventDefault();
    $languageMenu.toggleClass("open");
    $languageDropdown.fadeToggle(300);
  });

  $(document).on("click", function (e) {
    if (!$(e.target).closest(".language").length) {
      if ($languageMenu.hasClass("open")) {
        $languageMenu.removeClass("open");
        $languageDropdown.fadeOut(300);
      }
    }
  });
};

esign.cacheSelectors = function () {
  esign.cache = {
    // general
    isMobile: esign.isMobile(),

    // navigation
    $nav: $(".main-nav__wrap"),
  };
};

esign.navigation = function () {
  $(".main-nav__trigger").click(function (e) {
    e.preventDefault();
    $(this).next(".main-nav__wrap").slideToggle("fast");
    $("html").toggleClass("menu-open");
  });

  Response.crossover("width", function () {
    if (Response.band(767)) {
      esign.cache.$nav.css("display", "block");
      $("html").removeClass("menu-open");
    } else {
      esign.cache.$nav.css("display", "none");
    }
  });

  // Change size on scroll
  $(window)
    .scroll(function () {
      scrollPosition();
    })
    .scroll();

  function scrollPosition() {
    var className = "small";
    $header = $(".page-header");

    if (documentTopPosition() > 250) {
      $header.addClass(className);
    } else {
      $header.removeClass(className);
    }
  }

  function documentTopPosition() {
    return $(document).scrollTop();
  }
};

esign.responsiveVideos = function () {
  $(
    'iframe[src*="youtube.com/embed"], iframe[src*="youtube-nocookie.com/embed"], iframe[src*="player.vimeo"]'
  ).wrap('<div class="video-container"></div>');
};

// ---------------------------------------
// ----------------- Maps ----------------
// ---------------------------------------

function addContactGoogleMaps(container, latitude, longitude) {
  var zoom = 15,
    disable = true,
    scroll = false,
    styledMap = new google.maps.StyledMapType(googleMapsStyles, {
      name: "Styled Map",
    }),
    mapCenter = new google.maps.LatLng(latitude, longitude),
    mapOptions = {
      zoom: zoom,
      panControl: true,
      zoomControl: disable,
      scaleControl: true,
      mapTypeControl: false,
      streetViewControl: false,
      overviewMapControl: false,
      minZoom: 2,
      scrollwheel: scroll,
      center: mapCenter,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    },
    map = new google.maps.Map(document.getElementById(container), mapOptions);

  map.mapTypes.set("map_style", styledMap);
  map.setMapTypeId("map_style");

  google.maps.event.addDomListener(window, "resize", function () {
    map.setCenter(mapCenter);
  });

  return map;
}

function addContactGoogleMapsMarker(
  map,
  latitude,
  longitude,
  title,
  externUrl
) {
  var myLatlng = new google.maps.LatLng(latitude, longitude),
    markerIcon = {
      url: baseUrl + "assets/images/marker.png",
      size: new google.maps.Size(100, 128),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(25, 64),
      scaledSize: new google.maps.Size(50, 64),
    },
    marker = new google.maps.Marker({
      position: myLatlng,
      map: map,
      icon: markerIcon,
    });

  google.maps.event.addListener(marker, "click", function () {
    window.open(externUrl, "_blank");
  });

  return marker;
}

esign.initMaps = function () {
  var mapData = {
    title: "Esign",
    lat: 51.0325538,
    long: 3.7333816,
    externUrl:
      "https://www.google.be/maps/place/Esign+-+Web+%26+Graphics/@51.0325538,3.7333816,19z/data=!3m1!4b1!4m5!3m4!1s0x47c373970c763623:0xde317546f86febc9!8m2!3d51.0325538!4d3.7339288",
  };

  if ($("#map").length > 0) {
    var map = addContactGoogleMaps("map", mapData.lat, mapData.long);
    addContactGoogleMapsMarker(
      map,
      mapData.lat,
      mapData.long,
      mapData.title,
      mapData.externUrl
    );
  }
};

// ---------------------------------------
// ----------------- FORMS & Captcha -----
// ---------------------------------------

// normaal formulier versturen met post
esign.formAjax = function () {
  $(".form-ajax").submit(function (e) {
    var $form = $(this);
    $form.find('button,input[type="submit"]').attr("disabled", "disabled");

    $.post($form.attr("action"), $form.serializeArray(), function (data) {
      if (data.errors === false) {
        $form.html(data.result);
      } else {
        $form.find(".result").html(data.result);
        $form.find('button, input[type="submit"]').removeAttr("disabled");
      }
    });

    e.preventDefault();
    return false;
  });
};

// recaptcha formulier versturen met post
esign.formAjaxRecaptcha = function () {
  $(".form-ajax-recaptcha").submit(function (e) {
    e.preventDefault();
    var $form = $(this);

    if ($form.hasClass("validate")) {
      if ($form.validationEngine("validate") == false) {
        return false;
      }
    }

    if (
      $form.find('[name="g-recaptcha-response"]').length &&
      $form.find('[name="g-recaptcha-response"]').val() != ""
    ) {
      grecaptcha.reset();
    }

    grecaptcha.execute($form.find(".g-recaptcha").data("widgetid"));

    return false;
  });
};

// recaptcha submit handler
esign.recaptchaFormSend = function (form) {
  $form = $(form);
  $form.find('input[type="submit"], button').attr("disabled", "disabled");
  form_data = $form.serializeArray();

  $.post($form.attr("action"), form_data, function (data) {
    $("li").removeClass("error");
    var $result = $form.find(".result");

    if (data.errors === false) {
      $form.html(data.result);

      if (typeof ga === "function") {
        ga("send", "pageview", $form.attr("action"));
      }

      //todo GTM trigger event
    } else {
      $result.html(data.result);
      if (data.fields) {
        $.each(data.fields, function (i, field) {
          $(
            'input[name="' + field + '"],textarea[name="' + field + '"]'
          ).addClass("error");
        });
      }
    }

    $form.find('input[type="submit"], button').removeAttr("disabled");
  }).fail(function (response) {
    alert("Error: " + response.responseText);
  });
};

// recaptcha submit handler per formulier
var submitRecaptchaFormContact = function (token) {
  esign.recaptchaFormSend($("#form-contact"));
};
var submitRecaptchaFormNewsletter = function (token) {
  esign.recaptchaFormSend($("#form-newsletter"));
};

// explicit recaptcha methode (zodat er meerdere op 1 pagina kunnen)
var onloadReCaptchaCallback = function () {
  $(".g-recaptcha").each(function (i, el) {
    var attributes = {
      sitekey: $(el).data("sitekey"),
      size: $(el).data("size"),
      callback: $(el).data("callback"),
    };

    var widgetid = grecaptcha.render(el, attributes);
    $(el).data("widgetid", widgetid);
  });
};

// ---------------------------------------
// ----------------- FORMS validation ----
// ---------------------------------------

esign.formValidation = function () {
  if (esign.cache.isMobile) {
    $(".validate").validationEngine();
  } else {
    $(".validate").validationEngine({ scroll: false });
  }
};

// ---------------------------------------
// ----------------- UTILS -----------
// ---------------------------------------
esign.isMobile = function () {
  var deviceAgent = navigator.userAgent.toLowerCase(),
    isMobile =
      deviceAgent.match(/(iphone|ipod|ipad)/) ||
      deviceAgent.match(/(android)/) ||
      deviceAgent.match(/(iemobile)/) ||
      deviceAgent.match(/blackberry/i) ||
      deviceAgent.match(/bada/i) ||
      /OS [1-4]_[0-9_]+ like Mac OS X/i.test(navigator.userAgent);

  if (isMobile) {
    $("html").addClass("mobile");
  } else {
    $("html").addClass("no-mobile");
  }

  return isMobile;
};

// ---------------------------------------
// ----------------- DEV TOOLS -----------
// ---------------------------------------

// Log ga calls in development
esign.gaDevelopment = function () {
  if (typeof ga === typeof undefined) {
    window.ga = function () {
      var argumentsArray = [];
      for (var key in arguments) {
        if (arguments.hasOwnProperty(key)) {
          argumentsArray.push(arguments[key]);
        }
      }

      var msg =
        "[GA DEV] Call with arguments [" + argumentsArray.join(",") + "]";
      console.log("%c" + msg, "background: #ff9800; color: #fff;");
    };
  }
};

require([
  "requirejs/require",
  "jquery/dist/jquery",
  "js/polyfills/native-console",
  "response.js/response",
  "jquery-touchswipe/jquery.touchSwipe",
  "js/libs/validation/jquery.validationEngine",
  "jquery-match-height/dist/jquery.matchHeight",
  "@fancyapps/fancybox/dist/jquery.fancybox",
], function () {
  // Initialize on docready
  $(esign.init());
});

requirejs(["isotope-layout/dist/isotope.pkgd.min"], function (Isotope) {
  $(esign.isotope(Isotope));
});

requirejs(
  ["choices.js/assets/scripts/dist/choices.min.js"],
  function (Choices) {
    $(esign.choices(Choices));
  }
);

define("../resources/assets/js/esign", function(){});

